<template>
  <v-hover>
    <v-layout
      slot-scope="{ hover }"
      @click.prevent="$emit('clicked', value)"
      :style="{cursor: 'pointer', backgroundColor: hover ? '#F5FAFE' : 'white'}"
      px-4
      py-1
    >
      <v-flex>
        <div class="text-body-2 text-truncate">{{ value }}</div>
      </v-flex>
    </v-layout>
  </v-hover>
</template>

<script>
export default {
  props: {
    value: {}
  }
};
</script>

<style lang="scss" scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>